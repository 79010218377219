
import { Component, Vue } from 'vue-property-decorator'
import ClassicInterfaceInput from './ClassicInterfaceInput.vue'

@Component({
  components: {
    ClassicInterfaceInput,
  },
})
export default class ClassicInterface extends Vue {
  // Save the last used in cookies?
  // Propsync the fields and the values
  fieldOptions = [
    { value: 'au', text: 'Author' },
    { value: 'aur', text: 'Author or Related' },
    { value: 'ti', text: 'Title' },
    { value: 'r', text: 'Review Text' },
    { value: 'j', text: 'Journal' },
    { value: 'se', text: 'Series' },
    { value: 'pc', text: 'MSC Primary' },
    { value: 'sc', text: 'MSC Secondary' },
    { value: 'mr', text: 'MR Number' },
    { value: 'doi', text: 'DOI' },
    { value: 'rn', text: 'Reviewer' },
    { value: 'any', text: 'Anywhere' },
  ]

  timeRangeSelect = 'all'
  timeOperator = 'eq'
  timeOptions = [
    { value: 'eq', text: '=' },
    { value: 'lt', text: '<' },
    { value: 'gt', text: '>' },
  ]

  onlyYear = ''
  startYear = ''
  endYear = ''

  pubType = 'all'
  pubOptions = [
    { value: 'all', text: 'All' },
    { value: 'books', text: 'Books' },
    { value: 'journals', text: 'Journals' },
    // { value: 'proceedings', text: 'Proceedings' },
  ]

  inputCount = 4
  fields = Array(this.inputCount).fill('').map((_, i) => this.fieldOptions[i].value) // Init to the index
  values = Array(this.inputCount).fill('')
  operators = Array(this.inputCount).fill('AND')

  // cookieName = 'MRClassicInterface'
  storageName = 'MSN_ClassicInterface'

  storage = {
    fields: this.fields,
    // values: this.values,
    operators: this.operators,
    pubType: this.pubType,
    timeRangeSelect: this.timeRangeSelect,
    onlyYear: this.onlyYear,
    startYear: this.startYear,
    endYear: this.endYear,
  }

  mounted() {
    // this.loadCookies()
    this.loadStorage()
  }

  // loadCookies() {
  //   if (this.$cookies.get(this.cookieName)) {
  //     const cookieFields = this.$cookies.get(this.cookieName).split(',')
  //     for (let i = 0; i < cookieFields.length; i++) {
  //       this.$set(this.fields, i, cookieFields[i])
  //     }
  //   }
  // }

  // setCookies() {
  //   this.$cookies.set(this.cookieName, this.fields)
  // }

  loadStorage() {
    const test = localStorage.getItem(this.storageName)
    if (test) {
      const parsedStorage = JSON.parse(test)
      this.storage = parsedStorage
      this.fields = parsedStorage.fields
      this.operators = parsedStorage.operators
      this.pubType = parsedStorage.pubType
      this.timeRangeSelect = parsedStorage.timeRangeSelect
      this.onlyYear = parsedStorage.onlyYear
      this.startYear = parsedStorage.startYear
      this.endYear = parsedStorage.endYear
    }
  }

  setStorage() {
    localStorage.setItem(this.storageName, JSON.stringify(this.storage))
  }

  get fieldsToString() {
    let searchstring = ''
    const populated = this.values.map((x, index) => { if (x.trim() !== '') { return index } return -1 }).filter(x => x !== -1)
    for (let j = 0; j < populated.length; j++) {
      const i = populated[j]
      if (this.values[i].length > 0) {
        searchstring += this.fields[i] + ':(' + this.values[i] + ') '
        if (j !== populated.length - 1) {
          searchstring += this.operators[i] + ' ' // Don't add operator if this is the last one with a value
        }
      }
    }
    return searchstring.trim()
  }

  get yearRangeToString() {
    const yearFormat = ' y:'
    if (this.timeRangeSelect === 'all') {
      return ''
    } else if (this.timeRangeSelect === 'op') {
      // =, <, >
      if (this.timeOperator === 'eq') {
        return `${yearFormat}[${this.onlyYear}]`
      } else if (this.timeOperator === 'lt') {
        return `${yearFormat}[0 ${this.onlyYear}]`
      } else {
        return `${yearFormat}[${this.onlyYear} 9999]`
      }
    } else {
      // Year Range
      return `${yearFormat}[${this.startYear} ${this.endYear}]`
    }
  }

  get pubTypeToString() {
    if (this.pubType === 'all') {
      return ''
    } else if (this.pubType === 'books') {
      return ' et:("B" OR "BC" OR "BCZ")'
    } else {
      return ' et:("J" OR "JC")'
    }
  }

  search(): void {
    // this.setCookies()
    this.setStorage()
    this.$emit('search', `${this.fieldsToString}${this.yearRangeToString}${this.pubTypeToString}`)
  }

  clear(): void {
    this.values = Array(this.inputCount).fill('')
    this.timeRangeSelect = 'all'
    this.timeOperator = 'eq'
    this.onlyYear = ''
    this.startYear = ''
    this.endYear = ''
    this.pubType = 'all'
  }
}
