
// import { Component, Vue, Prop, Ref, VModel, Watch } from 'vue-property-decorator'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SearchHistoryCookie, MSNSearchStorage } from './types'
// import { SearchTabs } from '../navigation-tabs'

@Component
export default class SearchHistory extends Vue {
  @Prop() searchTab!: string
  searchHistory: SearchHistoryCookie[] = []
  pinnedSearches: SearchHistoryCookie[] = []
  // cookieName = 'MathSciNetSearchCookies'
  storageName = 'MSN_SearchHistory'
  defaultMaxHistoryLength = 5
  maxHistoryLength = -1
  defaultTab = 0 // Allow the user to set Pinned searches as the default. Not yet implemented
  selectedTab = 0

  storage: MSNSearchStorage = {
    defaultTab: 0,
    showHistory: true,
    maxHistoryLength: -1, // use defaultMaxHistoryLength
    history: {
      // keys for the different pages
    },
    pinned: {
      // keys for the different pages
    },
  }

  mounted() {
    // this.loadCookies()
    this.loadLocalStorage()
    this.$nextTick(() => {
      this.selectedTab = this.defaultTab
    })
  }

  loadLocalStorage() {
    const test = localStorage.getItem(this.storageName)
    if (test) {
      const parsedStorage = JSON.parse(test)
      this.storage = parsedStorage // Update this.storage so the other search tabs will keep their saved histories and pins
      if (parsedStorage.history[this.searchTab]) {
        this.searchHistory = parsedStorage.history[this.searchTab]
      } else {
        this.searchHistory = []
      }
      if (parsedStorage.pinned[this.searchTab]) {
        this.pinnedSearches = parsedStorage.pinned[this.searchTab]
      } else {
        this.pinnedSearches = []
      }
      if (parsedStorage.maxHistoryLength) {
        this.maxHistoryLength = parsedStorage.maxHistoryLength
      } else {
        this.maxHistoryLength = this.defaultMaxHistoryLength
      }
      this.defaultTab = parsedStorage.defaultTab !== null ? parsedStorage.defaultTab : this.defaultTab
    }
  }

  saveLocalStorage() {
    this.$set(this.storage.history, this.searchTab, this.searchHistory)
    this.$set(this.storage.pinned, this.searchTab, this.pinnedSearches)
    localStorage.setItem(this.storageName, JSON.stringify(this.storage))
  }

  clearLocalHistory() {
    this.searchHistory = []
    this.saveLocalStorage()
  }

  @Watch('searchTab', { immediate: false })
  onSearchTabChange() {
    // this.loadCookies()
    this.loadLocalStorage()
  }

  formatDate(dateStr: string): string {
    const now = new Date()
    const date = new Date(dateStr)

    const ms = now.getTime() - date.getTime()
    const minutes = Math.floor(ms / 1000 / 60)
    if (minutes < 60) {
      // const minuteText = minutes > 1 ? 'minutes' : 'minute'
      const minuteText = 'min.'
      return `${minutes} ${minuteText} ago`
    }
    const hours = Math.floor(minutes / 60)
    if (hours < 24) {
      const hourText = hours > 1 ? 'hours' : 'hour'
      return `${hours} ${hourText} ago`
    }
    const days = Math.floor(hours / 24)
    const dayText = days > 1 ? 'days' : 'day'
    return `${days} ${dayText} ago`
  }

  // Used by SearchControls to add to SearchHistory
  appendSearchHistory(text: string) {
    // If this text exists in the history, remove it, and add the updated
    const nonMatching = this.searchHistory.filter(x => x.text.trim().toLowerCase() !== text.trim().toLowerCase())
    const date = new Date()
    nonMatching.unshift({
      text: text.trim(),
      date: date.toString(),
    }) // Adds the new search to the front of the list
    this.searchHistory = nonMatching
    const maxLength = this.storage.maxHistoryLength > -1 ? this.storage.maxHistoryLength : this.defaultMaxHistoryLength
    this.searchHistory = this.searchHistory.slice(0, maxLength)
    this.saveLocalStorage()
  }

  emitSearch(text: string) {
    this.$emit('updateSearchText', text)
    this.$emit('search')
  }

  edit(text: string) {
    this.$emit('updateSearchText', text)
  }

  clearPinned() {
    this.pinnedSearches = []
    this.saveLocalStorage()
  }

  pin(text: string) {
    // Add to pinned searches
    if (this.pinnedSearches.filter(x => x.text.trim().toLowerCase() === text.trim().toLowerCase()).length > 0) {
      return // Already pinned
    }
    const date = new Date()
    this.pinnedSearches.unshift({
      text: text.trim(),
      date: date.toString(),
    })

    this.saveLocalStorage()
  }

  isPinned(text: string) {
    const matching = this.pinnedSearches.filter(x => x.text.trim().toLowerCase() === text.trim().toLowerCase())
    return matching.length > 0
  }

  unpin(text: string) {
    const matching = this.pinnedSearches.filter(x => x.text.trim().toLowerCase() === text.trim().toLowerCase())
    if (matching.length === 0) {
      return
    }
    const index = this.pinnedSearches.indexOf(matching[0])
    this.pinnedSearches.splice(index, 1)

    this.saveLocalStorage()
  }

  setShowHistory(collapsed: boolean) {
    this.storage.showHistory = collapsed
    this.saveLocalStorage()
  }

  getShowHistory(): boolean {
    return this.storage.showHistory
  }
}
