export type ListItem = {
  id?: string
  value: string
  text: (string | undefined)[]

}

export enum SearchKeyDirection {
  UP, DOWN
}
