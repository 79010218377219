
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { ClassicField } from './types'
@Component({})
export default class ClassicInterfaceInput extends Vue {
  @Prop({ default: true }) showBooleans!: boolean
  @Prop({ default: -1 }) index!: number
  @PropSync('field', { default: '' }) syncField!: string
  @PropSync('value', { default: '' }) syncValue!: string
  @PropSync('operator', { default: 'AND' }) syncOperator!: string
  @Prop({ default: [{ value: 'any', text: 'Anywhere' }] }) fieldOptions!: Array<ClassicField>

  booleanOptions = [
    { value: 'AND', text: 'and' },
    { value: 'OR', text: 'or' },
    { value: 'NOT', text: 'not' },
  ]

  selectId = 'search-term-'
  inputId = 'search-value-'
  boolId = 'search-bool-'

  mounted() {
    this.selectId = this.selectId + this.index
    this.inputId = this.inputId + this.index
    this.boolId = this.boolId + this.index
    if (this.syncField === '') {
      // Set the default field
      if (this.index === -1) {
        this.syncField = this.fieldOptions[0].value
      } else {
        this.syncField = this.fieldOptions[this.index].value
      }
    }
  }

  get searchFieldName() {
    return this.fieldOptions.filter(x => x.value === this.syncField)[0].text
  }

  search() {
    this.$emit('search')
  }
}
