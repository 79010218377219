import debounce from '@/utils/debounce'
import { Component, Prop, PropSync, VModel, Vue, Watch } from 'vue-property-decorator'
import { ListItem } from './types'
// import parser from '@/query-parser/parser'
import { getFieldTriggerInfo } from './utils'

@Component
export default class SuggestionsList extends Vue {
  @VModel({ type: String, required: true }) searchText!: string

  @Prop({ type: String, default: '' }) fieldName!: string
  @Prop({ required: true }) inputElement!: HTMLInputElement
  @Prop({ required: false, default: true }) active!: boolean
  @Prop({ type: Number, default: 2 }) minCharsToTrigger!: number
  @Prop({ type: Boolean, default: false }) fieldTriggerOnly!: boolean

  // @PropSync('showAutocompleteList', { type: Boolean, required: true }) syncedShowAutocompleteList!: boolean

  //
  // REACTIVE PROPERTIES
  //
  autocompleteListValues: ListItem[] = []

  //
  // WATCHERS
  //
  @Watch('inputElement')
  onInputElementChange(): void {
    this.initInputListener()
  }

  //
  // HOOKS
  //
  mounted(): void {
    // this.initInputListener()
  }

  destroyed(): void {
    // this.removeInputListener()
  }

  //
  // METHODS
  //
  // updateShowAutocompleteList(value: boolean): void {
  //   this.syncedShowAutocompleteList = value
  // }

  initInputListener(): void {
    if (this.inputElement) {
      this.inputElement.addEventListener('input', this.onInput)
    }
  }

  removeInputListener(): void {
    if (this.inputElement) {
      this.inputElement.removeEventListener('input', this.onInput)
    }
  }

  private onInput() {
    // Watch the input for modifications, but use the searchText specified
    if (this.active && this.inputElement && this.inputElement.selectionStart) {
      const cursorPosition = this.inputElement.selectionStart
      // TODO: parser is temporary disabled
      // const parserResults = parser.parse(text, {})
      const fields = [] // parserResults !== null ? parserResults.fields : []

      if (this.fieldTriggerOnly || fields.length > 0) {
        this.showWhenFieldIsPresent(fields, this.searchText, cursorPosition)
      } else if (fields.length === 0 && this.searchText.trim().length >= this.minCharsToTrigger) {
        this.debouncedFetchData(this.searchText.trim())
      // } else {
      //   this.updateShowAutocompleteList(false)
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showWhenFieldIsPresent(fields: any, text: string, cursorPosition: number | null) {
    const triggerInfo = getFieldTriggerInfo(fields, text, cursorPosition, this.fieldName)

    // Condition to open the autocomplete list
    if (triggerInfo.hasField && triggerInfo.minCharsEntered >= this.minCharsToTrigger) {
      this.debouncedFetchData(
        // this.inputElement.value.substring(
        //   triggerInfo.valueStartIndex,
        //   triggerInfo.valueEndIndex
        // ).trim()
        this.searchText.trim()
      )
    // } else {
    //   this.updateShowAutocompleteList(false)
    }
  }

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // private getFieldTriggerInfo(fields: any, text: string, cursorPosition: number | null) {
  //   const noInfo = {
  //     hasField: false,
  //     minCharsEntered: 0,
  //     valueStartIndex: -1,
  //     valueEndIndex: -1,
  //   }

  //   try {
  //     if (this.fieldName) {
  //       const field = this.findFieldBasedOnCursorPosition(fields, text, cursorPosition)

  //       if (field && field.name.value === this.fieldName && field.value) {
  //         switch (field.value.type) {
  //           case 'DATA':
  //             return {
  //               hasField: true,
  //               minCharsEntered: field.value.location.end - field.value.location.start,
  //               valueStartIndex: field.value.location.start,
  //               valueEndIndex: field.value.location.end,
  //             }
  //           default:
  //             return noInfo
  //         }
  //       }
  //     }
  //   } catch (e) {}

  //   return noInfo
  // }

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // private findFieldBasedOnCursorPosition(fields: any, text: string, cursorPosition: number | null) {
  //   let i = 0
  //   for (i = 0; i < fields.length - 1; i++) {
  //     const start = fields[i].name.location.end
  //     const end = fields[i + 1] ? fields[i + 1].name.location.start : text.length

  //     if (cursorPosition && cursorPosition >= start && cursorPosition <= end) break
  //   }

  //   return fields[i]
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchData(text: string): Promise<void> {
    throw new Error('fetchData must be implemented')
  }

  debouncedFetchData = debounce(this.fetchData, 350)
}
