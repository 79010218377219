export type FieldTriggerInfo = {
  hasField: boolean
  minCharsEntered: number
  valueStartIndex: number
  valueEndIndex: number
}

export function getFieldTriggerInfo(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  fields: any,
  text: string,
  cursorPosition: number | null,
  fieldName: string
): FieldTriggerInfo {
  const noInfo = {
    hasField: false,
    minCharsEntered: 0,
    valueStartIndex: 0,
    valueEndIndex: text.length,
  }

  try {
    if (fieldName) {
      const field = findFieldBasedOnCursorPosition(fields, text, cursorPosition)

      if (field && field.name.value === fieldName && field.value) {
        switch (field.value.type) {
          case 'DATA':
            return {
              hasField: true,
              minCharsEntered: field.value.location.end - field.value.location.start,
              valueStartIndex: field.value.location.start,
              valueEndIndex: field.value.location.end,
            }
          default:
            return noInfo
        }
      }
    }
  } catch (e) {}

  return noInfo
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findFieldBasedOnCursorPosition(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  fields: any,
  text: string,
  cursorPosition: number | null
): any {
  let i = 0
  for (i = 0; i < fields.length - 1; i++) {
    const start = fields[i].name.location.end
    const end = fields[i + 1] ? fields[i + 1].name.location.start : text.length

    if (cursorPosition && cursorPosition >= start && cursorPosition <= end) break
  }

  return fields[i]
}
