
import { Component, Vue, PropSync } from 'vue-property-decorator'

@Component
export default class DateSelectModal extends Vue {
  @PropSync('showModal', { type: Boolean }) syncedShowModal!: boolean

  addedChecked = true
  catalogedChecked = true
  reviewedChecked = true
  duration = 1

  dateType = 'y'
  dateTypeOptions = [
    { value: 'y', text: 'Publication Year' },
    { value: 'mp', text: 'Date Added' },
    { value: 'di', text: 'Date Cataloged' },
    { value: 'dr', text: 'Date Reviewed' },
  ]

  range = false
  startDate = ''
  endDate = ''

  get datePickerFormat() {
    if (this.dateType === 'y') {
      return {
        year: 'numeric',
        month: undefined,
        day: undefined,
      }
    } else {
      return {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }
    }
  }

  search() {
    let dateString = this.dateType === 'y' ? this.startDate.substring(0, 4) : this.startDate
    if (this.range) {
      // dateString = `${this.startDate} ${this.endDate}`
      dateString = this.dateType === 'y' ? `${this.startDate.substring(0, 4)} ${this.endDate.substring(0, 4)}` : `${this.startDate} ${this.endDate}`
    }
    this.$emit('search', `${this.dateType}:[${dateString}]`)
    this.syncedShowModal = false
    // Reset to the defaults
    this.startDate = ''
    this.endDate = ''
    this.dateType = 'y'
  }
}
