export type SearchTab = {
  readonly text: string
  readonly shortText: string
  readonly name: string
}

const publications: SearchTab = {
  text: 'Publications',
  shortText: 'Pub.',
  name: 'PublicationsSearch',
}

const journal: SearchTab = {
  text: 'Journals',
  shortText: 'Jour.',
  name: 'JournalSearch',
}

const series: SearchTab = {
  text: 'Series',
  shortText: 'Ser.',
  name: 'SeriesSearch',
}

const authors: SearchTab = {
  text: 'Authors',
  shortText: 'Au.',
  name: 'AuthorsSearch',
}

const mscSearch: SearchTab = {
  text: 'Search MSC',
  shortText: 'MSC',
  name: 'SearchMsc',
}

export const SearchTabs = {
  publications,
  journal,
  series,
  authors,
  mscSearch,
}
