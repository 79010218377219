
import { Component, Prop } from 'vue-property-decorator'
import { ListItem, SelectableList, SuggestionsList } from '@/components/selectable-list'
import { SerialsAPI } from '@/api/serials-api'
// import debounce from '@/utils/debounce'

@Component({
  components: {
    SelectableList,
  },
})
export default class JournalSuggestions extends SuggestionsList {
  @Prop({ required: true, default: false }) allowShowList!: boolean

  async fetchData(text: string) {
    const journals = await SerialsAPI.getJournalSuggestions(text, 10)

    this.autocompleteListValues = journals.map(journal => ({
      id: `${journal.id}`.trim(),
      value: journal.title,
      text: [journal.titleHTML, journal.issn],
    }))
  }

  hideListAndCancel() {
    (this.$refs.selectableList as SelectableList).hideListAndCancel()
  }

  private onItemSelectedHandler(item: ListItem) {
    this.$emit('itemSelected', item)
  }

  private handleFinalSelection(item) {
    this.$emit('finalSelection', item)
  }
}
