
import { Vue, Component, Prop, Watch, Ref, VModel } from 'vue-property-decorator'
import { ListItem, SearchKeyDirection } from './types'
import Mousetrap from 'mousetrap'
import { getFieldTriggerInfo } from './utils'
import parser from '@/query-parser/parser'

type Formatter = (left: string, right: string, text: string) => string
type Selection = {
  initial: {
    text: string,
    location: {
      start: number
      end: number
    }
    cursorPosition: number
  }
  previous: {
    text: string
    location: {
      start: number
      end: number
    }
  }
}

const defaultFormatter: Formatter = (left: string, right: string, text: string) => text

@Component
export default class SelectableList extends Vue {
  @VModel({ type: String, required: true }) searchText!: string

  @Ref() readonly listElement!: HTMLElement
  @Ref() readonly listItems!: HTMLElement[]

  @Prop({ required: true }) list!: ListItem[]
  @Prop({ required: true }) inputElement!: HTMLInputElement
  @Prop({ required: false, default: true }) active!: boolean
  @Prop({ default: () => defaultFormatter }) formatter!: Formatter
  @Prop({ default: false }) anchorOnField!: boolean
  @Prop({ default: '' }) anchorField!: string
  @Prop({ type: Boolean, default: false }) hasPrefix!: boolean
  @Prop({ required: true, default: false }) allowShowList!: boolean

  //
  // REACTIVE PROPERTIES
  //
  selectedItem: ListItem | null = null
  showList = true
  isBound = false // Debugging only

  //
  // NON-REACTIVE PROPERTIES
  //
  UP_DIRECTION!: SearchKeyDirection
  DOWN_DIRECTION!: SearchKeyDirection
  selection?: Selection

  //
  // WATCHERS
  //

  @Watch('allowShowList')
  onShowListChange() {
    if (this.allowShowList) {
      this.showList = true // reset the list to be shown
      this.initKeyboardShortcuts()
    }
  }

  @Watch('inputElement')
  onInputElementChange() {
    this.setPositioning()
  }

  @Watch('searchText')
  onSearchTextChange() {
    this.showList = true // more text typed can change suggestions
    if (!this.isBound && this.allowShowList) {
      // The keyboard shortcuts need to be re-initialized
      this.initKeyboardShortcuts()
    }
  }

  //
  // HOOKS
  //

  created() {
    this.UP_DIRECTION = SearchKeyDirection.UP
    this.DOWN_DIRECTION = SearchKeyDirection.DOWN
  }

  mounted() {
    this.initKeyboardShortcuts()
    this.setPositioning()
  }

  //
  // METHODS
  //

  initKeyboardShortcuts() {
    this.cleanupKeyboardShortcuts()
    if (this.allowShowList) {
      if (this.inputElement) {
        this.inputElement.classList.add('mousetrap')
      }
      this.isBound = true

      const moveSelector = this.anchorOnField ? this.moveSelectorAndSetValue : this.moveSelector
      const escape = this.anchorOnField ? this.hideListAndCancel : this.hideList
      const enter = this.anchorOnField ? this.onEnter : this.setValue

      Mousetrap.bind('up', moveSelector(this.UP_DIRECTION))
      Mousetrap.bind('down', moveSelector(this.DOWN_DIRECTION))
      Mousetrap.bind('shift+tab', moveSelector(this.UP_DIRECTION))
      Mousetrap.bind('tab', moveSelector(this.DOWN_DIRECTION))
      Mousetrap.bind('escape', escape)
      Mousetrap.bind('enter', function(e) {
        e.preventDefault()
        enter()
      })
      window.addEventListener('click', escape)
    }
  }

  cleanupKeyboardShortcuts() {
    // if (this.allowShowList) {
    // if (!this.allowShowList) {
    this.isBound = false
    Mousetrap.unbind('up')
    Mousetrap.unbind('down')
    Mousetrap.unbind('shift+tab')
    Mousetrap.unbind('tab')
    Mousetrap.unbind('escape')
    Mousetrap.unbind('enter')

    const escape = this.anchorOnField ? this.hideListAndCancel : this.hideList
    window.removeEventListener('click', escape)
    // }
  }

  onEnter() {
    this.hideList()
    this.$emit('finalSelection', this.selectedItem)
  }

  onBlur(index: number) {
    if (index === this.listItems.length - 1) {
      // End of list, start back at the top
      this.listItems[0].focus()
    }
  }

  hideList() {
    this.$nextTick(() => {
      if (this.selection) {
        // this.inputElement.selectionStart = this.inputElement.selectionEnd = this.selection.previous.location.end
        this.selection = undefined
      }
    })
    this.cleanupKeyboardShortcuts()
    this.showList = false
  }

  hideListAndCancel() {
    if (this.selection) {
      this.searchText = this.selection.initial.text

      this.$nextTick(() => {
        if (this.selection) {
          // this.inputElement.selectionEnd = this.selection.initial.cursorPosition
          this.selection = undefined
        }
      })
    }
    this.cleanupKeyboardShortcuts()
    this.showList = false
  }

  moveSelector(direction: SearchKeyDirection) {
    return (e: Mousetrap.ExtendedKeyboardEvent) => {
      if (this.allowShowList && this.active) {
        e.preventDefault()

        const movement = direction === this.UP_DIRECTION ? -1 : 1
        let index = this.list.findIndex(item => item === this.selectedItem) + movement
        // This line is needed to prevent the index go out of bounds
        index = index < 0 ? this.list.length - 1 : index % this.list.length

        // ADJUST SCROLL POSITION
        const selectedElement = this.listItems[index]
        const pos = selectedElement.getBoundingClientRect().top - this.listElement.getBoundingClientRect().top
        if (index === 0) {
          this.listElement.scrollTop = 0
        } else if (index === this.list.length - 1) {
          this.listElement.scrollTop = this.listElement.scrollHeight
        } else if (pos >= 370) {
          this.listElement.scrollTop += selectedElement.getBoundingClientRect().height
        } else if (pos < 0) {
          this.listElement.scrollTop -= selectedElement.getBoundingClientRect().height
        }

        this.selectItem(index)
      }
    }
  }

  moveSelectorAndSetValue(direction: SearchKeyDirection) {
    return (e: Mousetrap.ExtendedKeyboardEvent) => {
      this.moveSelector(direction)(e)
      this.setValueFromAnchorPositions()
    }
  }

  setValueFromAnchorPositions() {
    if (this.allowShowList && this.selectedItem) {
      // const formattedText = ''

      if (!this.selection) {
        const parserResults = parser.parse(this.searchText, {})
        const fields = parserResults !== null ? parserResults.fields : []
        const cursorPosition = this.inputElement.selectionStart
        const triggerInfo = getFieldTriggerInfo(fields, this.searchText, cursorPosition, this.anchorField)

        this.selection = {
          initial: {
            text: this.searchText,
            location: {
              start: triggerInfo.valueStartIndex,
              end: triggerInfo.valueEndIndex,
            },
            cursorPosition: cursorPosition || triggerInfo.valueEndIndex,
          },
          previous: {
            text: this.searchText,
            location: {
              start: triggerInfo.valueStartIndex,
              end: triggerInfo.valueEndIndex,
            },
          },
        }
      }

      const leftText = this.selection.previous.text.slice(0, this.selection.previous.location.start)
      const rightText = this.selection.previous.text.slice(this.selection.previous.location.end)
      // const formattedText = this.formatter(leftText, rightText, this.selectedItem.value)
      const newText = leftText + this.selectedItem.value + rightText
      const newEndPosition = this.selection.previous.location.start + this.selectedItem.value.length

      this.selection.previous.text = newText
      this.selection.previous.location.end = newEndPosition

      this.searchText = newText

      this.$nextTick(() => {
        if (!this.selection) return

        this.inputElement.selectionStart = this.selection.previous.location.start
        this.inputElement.selectionEnd = this.selection.previous.location.end
      })
    }
  }

  setValue() {
    if (this.allowShowList && this.list.length > 0 && this.selectedItem) {
      const searchText = this.searchText
      const selectionStart = this.inputElement.selectionStart
      const leftText = searchText.slice(0, selectionStart as number)
      const rightText = searchText.slice(selectionStart as number)
      const formattedText = this.formatter(leftText, rightText, this.selectedItem.value)

      this.searchText = `${leftText}${formattedText}${rightText}`

      this.$nextTick(() => {
        if (selectionStart) {
          this.inputElement.selectionEnd = selectionStart + formattedText.length
        }
      })
      this.$emit('finalSelection', this.selectedItem)
      this.hideList()
      this.inputElement.focus()
    } else {
      // Nothing was selected, just pass the enter through
      this.$emit('enterPassThrough')
    }
  }

  selectItem(itemIndex: number) {
    this.selectedItem = this.list[itemIndex]
    this.$emit('itemSelected', this.selectedItem)
  }

  clickHandler(itemIndex: number) {
    this.selectItem(itemIndex)

    if (this.anchorOnField) {
      this.setValueFromAnchorPositions()
      this.$emit('finalSelection', this.selectedItem)
      this.hideList()
      this.inputElement.focus()
    } else {
      this.setValue()
    }
  }

  setPositioning() {
    if (this.inputElement) {
      const el = this.$refs.listElement as HTMLElement
      el.remove()
      el.style.transform = `translateY(${this.inputElement.getBoundingClientRect().height}px)`
      this.inputElement.after(el)
    }
  }
}
