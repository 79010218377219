
import { Vue, Component, Prop, VModel, Ref } from 'vue-property-decorator'
import { SearchToken, SearchTokenSeparator } from './types'
import SearchControls from './SearchControls.vue'
import { SearchTab } from '../navigation-tabs'
import { scrollTo } from '@/utils/utils'
import { SearchParams } from '@/global-types'

@Component({
  components: {
    SearchControls,
  },
})
export default class SearchBar extends Vue {
  @VModel() searchText!: string

  @Ref() readonly hrLine!: HTMLHRElement
  @Ref() readonly searchControls!: Vue

  @Prop() selectedTab!: SearchTab;
  @Prop({ default: () => [] }) searchFields!: SearchToken[]
  @Prop({ default: () => [] }) searchOperators!: SearchTokenSeparator[]
  @Prop({ default: false }) searchWhenListIsOpened!: boolean
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ required: true }) searchParams!: SearchParams

  search(searchText: string) {
    scrollTo('#navigation-tabs')
    this.$emit('search', searchText)
  }

  clear() {
    this.$emit('clear')
  }
}
