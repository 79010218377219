
import { Component, Vue, PropSync } from 'vue-property-decorator'

@Component
export default class SearchNewestModal extends Vue {
  @PropSync('showModal', { type: Boolean }) syncedShowModal!: boolean

  addedChecked = true
  catalogedChecked = true
  reviewedChecked = true
  duration = 1

  search() {
    this.$emit('search', this.addedChecked, this.catalogedChecked, this.reviewedChecked, this.duration)
    this.syncedShowModal = false
  }
}
