
import { Component, Prop } from 'vue-property-decorator'
import { AuthorsAPI } from '@/api/authors-api'
import { SuggestionsList, SelectableList } from '@/components/selectable-list'

@Component({
  components: {
    SelectableList,
  },
})
export default class AuthorNameSuggestions extends SuggestionsList {
  @Prop({ required: true, default: false }) allowShowList!: boolean

  async fetchData(text: string) {
    const authors = await AuthorsAPI.getAuthorSuggestions(text)

    this.autocompleteListValues = authors.map(author => ({
      value: author.name,
      text: [author.nameHTML],
    }))
  }

  hideListAndCancel() {
    (this.$refs.selectableList as SelectableList).hideListAndCancel()
  }

  private handleFinalSelection(item) {
    this.$emit('finalSelection', item)
  }
}
