
// import { ScreenReader } from '@/utils/screen-reader'
// const screenReader = new ScreenReader()

import { Component, Vue, Prop, Ref, VModel, Watch } from 'vue-property-decorator'
import { SearchToken, SearchTokenSeparator, TokenizedSearchField, TokenizedSearchSegment } from './types'
import { SelectableList, ListItem } from '@/components/selectable-list'
import { AuthorNameSuggestions } from '@/components/author-name-suggestions'
import { SeriesSuggestions, JournalSuggestions } from '@/components/serials-suggestions'
import Mousetrap from 'mousetrap'
import { SearchTab, SearchTabs } from '../navigation-tabs'
import SearchNewestModal from './SearchNewestModal.vue'
import SearchHistory from './SearchHistory.vue'
import ClassicInterface from './ClassicInterface.vue'
import DateSelectModal from './DateSelectModal.vue'
import { SearchParams } from '@/global-types'
import { RESET_LAST_SEARCH_SEARCHPARAMS, RESET_LAST_SEARCH_STATE } from '@/store'

@Component({
  components: {
    SelectableList,
    AuthorNameSuggestions,
    SeriesSuggestions,
    JournalSuggestions,
    SearchNewestModal,
    SearchHistory,
    ClassicInterface,
    DateSelectModal,
  },
})
export default class SearchControls extends Vue {
  @VModel({ type: String }) searchText!: string
  @Ref() readonly searchInputBar!: HTMLElement

  @Prop() selectedTab!: SearchTab;
  @Prop() searchFields!: SearchToken[]
  @Prop() searchOperators!: SearchToken[]
  @Prop({ default: false }) searchWhenListIsOpened!: boolean
  @Prop({ default: false }) internalAccess!: boolean
  @Prop({ required: true }) searchParams!: SearchParams

  searchItems = [
    {
      selected: '',
      value: ' ',
      editing: false,
    },
  ]

  //
  // REACTIVE PROPERTIES
  //
  // currentSearchToken: TokenizedSearchField = { field: '', value: '', wrapper: '', raw: '' }
  currentSearchToken: TokenizedSearchField = { field: '', segments: [{ wrapper: '', value: '', raw: '' }], raw: '' }
  currentSegmentIndex = 0
  lastTokenIndex = 0 // was -1, but that messed with initial suggestions
  isMounted = false
  atTop = false
  cursorPosition = 0
  autofillSuggestion = ''
  showBubbleFormatting = false
  searchActive = false
  selectedItemId = ''
  showList = false
  showSearchNewest = false
  showDateSelectModal = false
  showFields = false
  showClassicInterface = false // Not in cookies yet
  showHistory = true
  listValues: ListItem[] = []
  showAutocompleteList = false
  sortMethod = 'Tag' // 'Tag' or 'Name'
  logoHeaderUrl = ''
  inputElement: HTMLInputElement | null = null
  SearchTabs = SearchTabs
  lowercaseBool = /(^|\s|\))(and|or|not)(\s|$|\()/gm
  wrapperPattern = new RegExp('([^"\\[\\(]+)|(("(.+?)")|(\\[(.+?)\\])|(\\((.+?)\\)))', 'g') // Groups into items that are inside "", [], (), or outside that

  fieldTokenPattern = new RegExp('::', 'gi') // Updated after you get the fields
  lastSearchText = '' // Used to disable suggestions after the search has been issued

  /* -------------------------------------------------------------------------- */
  /*                                   WATCHER                                  */
  /* -------------------------------------------------------------------------- */
  @Watch('autofillSuggestion', { immediate: false })
  checkIfAutoCompleteApplied() {
    // Problem: Ctrl+A and deleting the search text - search tokens don't update fast
    if (this.currentSearchToken.segments[this.currentSegmentIndex] && this.autofillSuggestion.trim() !== this.currentSearchToken.segments[this.currentSegmentIndex].value.trim()) {
      if (this.searchText.length === 0) {
        // This works for selecting ALL the text and deleting it, but not tested for smaller selections
        // ...
        // `au:"some"`
        // delete it all
        // `auth` select the author name suggestions
        // search fires with the partial suggestion.
        // console.log('No text!')
        this.setCursorPosition(0)
        return
      }
      const tokenIndex = this.lastTokenIndex
      const preSegment = this.currentSearchToken.segments.slice(0, this.currentSegmentIndex).map(x => x.raw).join('')
      const postSegment = this.currentSearchToken.segments.slice(this.currentSegmentIndex + 1).map(x => x.raw).join('')
      const autofilledSegment = this.currentSearchToken.segments[this.currentSegmentIndex].raw.replace(this.currentSearchToken.segments[this.currentSegmentIndex].value, this.autofillSuggestion)
      const joinedSegments = preSegment + autofilledSegment + postSegment
      const preTokens = this.searchTokens.slice(0, tokenIndex).map(x => x.raw).join('')
      const postTokens = this.searchTokens.slice(tokenIndex + 1).map(x => x.raw).join('')
      this.searchText = preTokens + joinedSegments + postTokens
      let newCursorPosition = preTokens.length + preSegment.length + autofilledSegment.length
      if (this.currentSearchToken.segments[this.currentSegmentIndex].wrapper.length > 0) {
        newCursorPosition -= 1
      }
      if (this.cursorPosition === newCursorPosition) {
        // There's a bug with identical cursor positions not triggering updates
        this.currentSearchToken.raw = joinedSegments
        this.currentSearchToken.segments[this.currentSegmentIndex].raw = autofilledSegment
        this.currentSearchToken.segments[this.currentSegmentIndex].value = this.autofillSuggestion
      }
      this.setCursorPosition(newCursorPosition)
    }
    // Field Suggestions aren't handled here. They're part of onFieldSelectedHandler()
  }

  @Watch('searchText', { immediate: false })
  clearLastSearchText() {
    if (this.searchText.length === 0) {
      // Search was cleared, allow normal search suggestions
      // console.log('cleared search')
      this.lastSearchText = ''
      this.autofillSuggestion = ''
    } else {
      // console.log('uncleared?')
      if (this.lastSearchText !== this.searchText) {
        // The user started typing again after the search was issued, meaning suggestions should resume as normal
        this.lastSearchText = ''
      }
    }
  }

  @Watch('cursorPosition', { immediate: false })
  updateCurrentSearchToken() {
    const tokenIndex = this.getTokenIndexAtPosition(this.cursorPosition)
    if (this.lastTokenIndex !== tokenIndex) {
      // The cursor is on a different field now, and the auto complete suggestions need to change
      this.clearAutocompleteSuggestions()
      this.lastTokenIndex = tokenIndex
    }
    if (this.lastTokenIndex >= this.searchTokens.length) {
      this.lastTokenIndex = this.searchTokens.length - 1
    }
    this.currentSearchToken = this.searchTokens[tokenIndex]
    this.currentSegmentIndex = this.getSegmentIndexAtPosition(this.cursorPosition)
    if (this.currentSegmentIndex === -1) {
      // The syntax isn't complete, something like `au:"brown` (no closing quote). Don't show suggestions
      this.autofillSuggestion = ''
    } else {
      // Prevent the currentSegmentIndex from being a completed field tag. This prevents the autofill suggestions from defaulting to using `au:`
      if (this.searchTokens[tokenIndex].field.length > 0 && this.searchTokens[tokenIndex].field + ':' === this.searchTokens[tokenIndex].segments[this.currentSegmentIndex].raw.trim()) {
        this.currentSegmentIndex += 1
      }
      // Adding a trim deletes user added space... but the suggestions don't match if there's a space in front.
      // this.autofillSuggestion = this.currentSearchToken.segments[this.currentSegmentIndex].value
      this.autofillSuggestion = this.currentSearchToken.segments[this.currentSegmentIndex].value.trim()
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                           NON-REACTIVE PROPERTIES                          */
  /* -------------------------------------------------------------------------- */
  selectedSeparator?: SearchTokenSeparator
  // UP_DIRECTION!: SearchKeyDirection
  // DOWN_DIRECTION!: SearchKeyDirection
  mousetrapInstance!: Mousetrap.MousetrapInstance

  /* -------------------------------------------------------------------------- */
  /*                             COMPUTED PROPERTIES                            */
  /* -------------------------------------------------------------------------- */
  get fieldsAndOperatorsLoaded() {
    // return this.searchFields.length > 0 && this.searchOperators.length > 0
    // TEMPORARY: Disable Add Field, Add Operator, Search Newest on everything but publications search
    return this.selectedTab.name === 'PublicationsSearch' && this.searchFields.length > 0 && this.searchOperators.length > 0
  }

  get showDateSelect() {
    return this.selectedTab.name === 'PublicationsSearch'
  }

  get accessSearchFields() {
    // Apply internal access filter
    if (this.internalAccess) {
      return this.searchFields
    }
    return this.searchFields.filter(x => x.internal === undefined || x.internal === false).sort((a, b) => a.value.localeCompare(b.value))
  }

  get standardSearchFields() {
    const filtered = this.searchFields.filter(x => (x.internal === undefined || x.internal === false) && (x.advanced === undefined || x.advanced === false))
    if (this.sortMethod === 'Tag') {
      return filtered.sort((a, b) => a.value.localeCompare(b.value))
    } else {
      return filtered.sort((a, b) => a.name.localeCompare(b.name))
    }
  }

  get advancedSearchFields() {
    const filtered = this.searchFields.filter(x => (x.internal === undefined || x.internal === false) && x.advanced)
    if (this.sortMethod === 'Tag') {
      return filtered.sort((a, b) => a.value.localeCompare(b.value))
    } else {
      return filtered.sort((a, b) => a.name.localeCompare(b.name))
    }
  }

  get internalSearchFields() {
    const filtered = this.searchFields.filter(x => x.internal)
    if (this.sortMethod === 'Tag') {
      return filtered.sort((a, b) => a.value.localeCompare(b.value))
    } else {
      return filtered.sort((a, b) => a.name.localeCompare(b.name))
    }
  }

  get tempSpacing() {
    if (this.isMounted && this.searchInputBar) {
      const height = this.searchInputBar.offsetHeight

      return `height:${height}px;`
    }

    return ''
  }

  get fieldSuggestions(): ListItem[] {
    if (this.currentAllowedAutoComplete !== 'fields') {
      this.showList = false
      return []
    }
    const rawSplit = this.splitCurrentWord()
    if (rawSplit.current.trim().length === 0) {
      this.showList = false
      return []
    }
    const currentWord = rawSplit.current.toLowerCase().trim()

    let matchingFields: SearchToken[] = []
    if (currentWord.length === 1) {
      matchingFields = this.searchFields.filter(x => x.value.startsWith(currentWord) || x.name.toLowerCase().startsWith(currentWord))
    } else {
      if (currentWord.includes(':')) {
        this.showList = false
        return []
      }
      try {
        const otherWordRegex = RegExp(`\\W${currentWord}`, 'g')
        matchingFields = this.searchFields.filter(x => x.value.startsWith(currentWord) || x.name.toLowerCase().startsWith(currentWord) || x.name.toLowerCase().match(otherWordRegex))
      } catch {
        // There was an error with the regex generated, likely catching a (, ), [, or ]
        matchingFields = []
      }
    }

    // Sort the matchingFields by Basic, Advanced, Internal
    matchingFields = matchingFields.sort((a, b) => {
      if (a.advanced !== b.advanced) {
        if (a.advanced) {
          return 1
        } else {
          return -1
        }
      }
      if (a.internal !== b.internal) {
        if (a.internal) {
          return 1
        } else {
          return -1
        }
      }
      return a.name.localeCompare(b.name)
    }).filter(v => (this.internalAccess ? true : !v.internal))

    // Set the show list to match if there are fields to suggest
    this.showList = matchingFields.length > 0

    return matchingFields.map(x => ({ value: x.value, text: [x.name] }))
  }

  get lastSearchHasErrors() {
    return this.$store.getters.lastSearchHasErrors
  }

  get lastSearchErrorMessage() {
    return this.$store.getters.lastSearchErrorMessage
  }

  get lastSearchNotMatchingResults() {
    return this.$store.getters.lastSearchNotMatchingResults
  }

  get lastSearchTypeOfItem() {
    return this.$store.getters.lastSearchTypeOfItem
  }

  get lastSearchIsExpandedSearch() {
    return this.$store.getters.lastSearchIsExpandedSearch
  }

  get selectedText(): string {
    // Returns a string matching what the user has selected
    if (window && window.getSelection() !== null) {
      return window.getSelection()!.toString()
    }
    return ''
  }

  get hasWrapper(): boolean {
    return this.currentSearchToken.segments[this.currentSegmentIndex].wrapper.length === 2
  }

  get allowSuggestions(): boolean {
    const diffSearch = this.lastSearchText !== this.searchText.trim()
    const genericSuggestionsAllowed = diffSearch && this.searchText.trim().length > 0 && this.autofillSuggestion.trim().length > 0
    if (this.selectedTab.name === 'PublicationsSearch' && this.currentAllowedAutoComplete !== 'fields') {
      // if in PublicationsSearch, and token is autofill, then make sure the current token has wrapper before allowing suggestions
      const formatsWithAutocomplete = ['author', 'journal', 'series']
      const autofillSuggestionFields = this.searchFields.filter(x => x.format && formatsWithAutocomplete.indexOf(x.format) > -1).map(x => x.value)
      const isAutofillField = autofillSuggestionFields.indexOf(this.currentSearchToken.field) > -1
      if (isAutofillField) {
        return genericSuggestionsAllowed && isAutofillField && this.hasWrapper
      }
    }
    return genericSuggestionsAllowed
  }

  get supportsClassicInterface() {
    return this.$route.name === 'PublicationsSearch'
  }

  get currentAllowedAutoComplete() {
    // Which autocomplete is allowed to display based on location
    let defaultAutoComplete = 'fields'
    if (this.selectedTab.name === this.SearchTabs.journal.name) {
      defaultAutoComplete = 'journal'
    }
    if (this.selectedTab.name === this.SearchTabs.series.name) {
      defaultAutoComplete = 'series'
    }
    if (this.selectedTab.name === this.SearchTabs.authors.name) {
      defaultAutoComplete = 'author'
    }

    // Match Field with .format='' to determine the auto complete
    if (this.currentSearchToken.field.length > 0) {
      // Check if the current segmentIndex has a wrapper or not.
      // Shouldn't need to check if this is null, but I do...
      if (this.currentSearchToken.segments[this.currentSegmentIndex] && this.currentSearchToken.segments[this.currentSegmentIndex].wrapper.length < 2) {
        // console.log('current segment wrapper is less than 2')
        return defaultAutoComplete
      } else {
        // console.log('else')
        const matchingField = this.searchFields.filter(x => x.value === this.currentSearchToken.field.toLowerCase())
        if (matchingField.length > 0 && matchingField[0].format !== undefined) {
          return matchingField[0].format // Not every format will have a matching autocomplete, but it keeps the logic simple
        }
      }
    }

    return defaultAutoComplete
  }

  get cursorInField() {
    return this.currentSearchToken.field.length > 0
  }

  get searchTokens(): TokenizedSearchField[] {
    const tokenized: TokenizedSearchField[] = []
    const tokenStartIndexes = [...this.searchText.matchAll(this.fieldTokenPattern)].map(x => {
      if (!x.index) {
        return 0 // forcing it to be a number
      }
      if (this.searchText[x.index].match(/\W/)) {
        return x.index + 1
      } else {
        return x.index
      }
    })
    if (tokenStartIndexes[0] !== 0) {
      // Need a token for the text that's before the first field
      const preFields = this.searchText.substring(0, tokenStartIndexes[0])
      tokenized.push({
        field: '',
        segments: [
          {
            value: preFields,
            wrapper: '',
            raw: preFields,
          },
        ],
        raw: preFields,
      })
    }
    for (let i = 0; i < tokenStartIndexes.length; i++) {
      const end = i === tokenStartIndexes.length - 1 ? this.searchText.length : tokenStartIndexes[i + 1]
      const tokenString = this.searchText.substring(tokenStartIndexes[i], end)
      const field = tokenString.split(':')[0]
      const values = tokenString.substring(tokenString.indexOf(':') + 1)
      tokenized.push({
        field: field.trim().toLocaleLowerCase(),
        segments: [{ value: field + ':', wrapper: '', raw: field + ':' }].concat(this.getSegments(values)),
        raw: tokenString,
      })
    }
    return tokenized
  }

  get lowercaseBooleans() {
    // Test String:
    // s:(some) and t:[and] some or another j:(something)not(
    // t:"This and That" OR tt:"To be or not to be"
    const nonFieldCombined = this.searchTokens.map(x => x.segments.filter(y => y.wrapper === '').map(y => y.raw)).join('')
    if (this.lowercaseBool.test(nonFieldCombined)) {
      const usedBooleans = nonFieldCombined.match(this.lowercaseBool)
      if (usedBooleans) {
        const ub = usedBooleans.map(x => x.trim())
        return [...new Set(ub)]
      }
    }
    return []
  }

  /* -------------------------------------------------------------------------- */
  /*                               LIFECYCLE HOOKS                              */
  /* -------------------------------------------------------------------------- */
  async mounted() {
    // this.initCookies()
    this.initKeyboard()
    this.initSearchbarFocus()
    this.initScrollListener()

    // Loads logo dynamically
    import('@/assets/logo.png').then(r => (this.logoHeaderUrl = r.default))

    if (this.searchText.length > 0) {
      this.showHistory = false // Redirected from another search
      this.showFields = false
      this.showClassicInterface = false
    } else {
      const history = (this.$refs.searchHistory as SearchHistory)
      this.showHistory = history.getShowHistory() // If the user hid history, do not show it now. Otherwise, show history

      const classic = localStorage.getItem(this.$route.name + 'ShowClassicInterface')
      if (classic) {
        this.showClassicInterface = classic.toLowerCase() === 'true'
        if (this.showClassicInterface) {
          this.showHistory = false // Since showing history is the opposite of showing the classic interface, it's hidden
          this.showFields = false
        }
      }
      if (this.showClassicInterface === false) {
        const storedShowFields = localStorage.getItem(this.$route.name + 'ShowFields')
        if (storedShowFields) {
          this.showFields = storedShowFields.toLowerCase() === 'true'
        }
      }
    }

    this.isMounted = true
    this.listValues = this.searchFields.map(x => ({
      value: x.value,
      text: [x.name],
    }))

    const sortMethod = localStorage.getItem('FieldSortMethod')
    if (sortMethod) {
      this.sortMethod = sortMethod
    }

    // Set the regexp used for spliting the search tokens
    const tags = this.searchFields.map(x => x.value).join('|')

    // this.fieldTokenPattern = new RegExp(`(?:^|\\W)(${tags}):\\s*(((\\w|\\s|\\W)(?!(${tags}):))*)`, 'gi')
    this.fieldTokenPattern = new RegExp(`(?:^|\\W)(${tags}):`, 'gi')
  }

  beforeDestroyed() {
    this.cleanupKeyboard()
    this.cleanupSearchbarFocus()
    this.cleanupScrollLisener()
  }

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  splitCurrentWord() {
    // Splits currentSearchToken.raw into the current word the user is writing, the text before, and the text after
    // Spaces should be considered part of the before or after text
    // Need to know where the cursor is inside the token.
    const priorTextLength = this.searchTokens.slice(0, this.lastTokenIndex).map(x => x.raw.length).reduce((a, b) => a + b, 0)
    const rawCursorPosition = this.cursorPosition - priorTextLength

    const rawTextBeforeCursor = this.currentSearchToken.raw.substring(0, rawCursorPosition)
    const rawTextAfterCursor = this.currentSearchToken.raw.substring(rawCursorPosition)
    const beforeCurrentWord = rawTextBeforeCursor.includes(' ') ? rawTextBeforeCursor.substring(0, rawTextBeforeCursor.lastIndexOf(' ') + 1) : ''
    const afterCurrentWord = rawTextAfterCursor.includes(' ') ? rawTextAfterCursor.substring(rawTextAfterCursor.indexOf(' ')) : ''
    const currentWord = this.currentSearchToken.raw.substring(beforeCurrentWord.length, this.currentSearchToken.raw.length - afterCurrentWord.length)
    const results = { before: beforeCurrentWord, current: currentWord, after: afterCurrentWord }
    return results
  }

  onFieldSelectedHandler(item: ListItem) {
    // Delete the word the suggestion was made on, then add the new field in it's place
    const rawSplit = this.splitCurrentWord()
    // Rebuild the searchText, ommitting the current word
    this.searchText = this.searchTokens.slice(0, this.lastTokenIndex).map(x => x.raw).join('') + rawSplit.before + rawSplit.after + this.searchTokens.slice(this.lastTokenIndex + 1).map(x => x.raw).join('')
    // Place the cursor where the word was
    this.setCursorPosition(this.cursorPosition - rawSplit.current.length)
    this.$nextTick(() => {
      this.addField(item.value)
    })
  }

  onAutocompleteSelectedHandler() {
    // Move the cursor out of the search token
    const priorTextLength = this.searchTokens.slice(0, this.lastTokenIndex).map(x => x.raw.length).reduce((a, b) => a + b, 0)
    this.setCursorPosition(priorTextLength + this.currentSearchToken.raw.length)
  }

  initScrollListener() {
    window.addEventListener('scroll', this.scrollHandler)
  }

  cleanupScrollLisener() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  initSearchbarFocus() {
    const el = document.getElementById('searchTextInput')
    if (el) {
      el.addEventListener('focusin', this.focusChanged)
      el.addEventListener('focusout', this.focusChanged)
      el.addEventListener('mouseup', this.updateCursorPosition)
      el.addEventListener('keyup', this.updateCursorPosition)
      el.addEventListener('input', this.updateCursorPosition)
    }
  }

  cleanupSearchbarFocus() {
    const el = document.getElementById('searchTextInput')
    if (el) {
      el.removeEventListener('focusin', this.focusChanged)
      el.removeEventListener('focusout', this.focusChanged)
      el.removeEventListener('mouseup', this.updateCursorPosition)
      el.removeEventListener('keyup', this.updateCursorPosition)
      el.removeEventListener('input', this.updateCursorPosition)
    }
  }

  focusChanged() {
    if (document.activeElement) {
      this.searchActive = (document.activeElement.id === 'searchTextInput') || false
    }
  }

  scrollHandler() {
    const top = document.getElementById('scrollDetector')?.getBoundingClientRect().top || 0
    this.atTop = top <= 0
  }

  updateCursorPosition(event) {
    this.cursorPosition = event.target.selectionStart
    // console.log(this.cursorPosition)
  }

  leftKeyPress(event: KeyboardEvent) {
    const selection = window.getSelection()
    // Not sure if anchor, base, extent, or focus should be used
    if (selection && selection.anchorOffset === 0) {
      // console.log('Pressed Left while at position 0')
      try {
        const target = event.target as HTMLElement
        const prev = target.previousSibling as HTMLElement
        prev.focus()
      } catch {}
    }
  }

  rightKeyPress(event: KeyboardEvent) {
    const selection = window.getSelection()
    // Not sure if anchor, base, extent, or focus should be used
    if (selection && selection.anchorNode && selection.anchorNode.textContent && selection.anchorOffset === selection.anchorNode.textContent.length) {
      // console.log('Pressed Right while at the end')
      try {
        const target = event.target as HTMLElement
        const next = target.nextSibling as HTMLElement
        next.focus()
      } catch {}
    }
  }

  initKeyboard() {
    this.cleanupKeyboard()
    this.inputElement = (this.$refs.searchTextInput as Vue).$el as HTMLInputElement
    if (this.inputElement) {
      this.mousetrapInstance = Mousetrap(this.inputElement)
      // this.mousetrapInstance.bind(this.shortcuts.addField, this.addField)
      // this.mousetrapInstance.bind(this.shortcuts.addOp, this.showOperators)
    }
  }

  cleanupKeyboard() {
    if (this.mousetrapInstance) {
      // this.mousetrapInstance.unbind(this.shortcuts.addField)
      // this.mousetrapInstance.unbind(this.shortcuts.addOp)
    }
  }

  updateShowList(value: boolean) {
    // This might be old code that can be deleted
    this.showAutocompleteList = false
    this.showList = value
  }

  onShowAutocompleteListUpdated(value: boolean) {
    // This might be old code that can be deleted
    this.showList = false
    this.showAutocompleteList = value
  }

  clearAutocompleteSuggestions() {
    this.autofillSuggestion = ''
    const authors = this.$refs.authorSuggestions as AuthorNameSuggestions
    authors.hideListAndCancel()
    const journals = this.$refs.journalSuggestions as JournalSuggestions
    journals.hideListAndCancel()
    const series = this.$refs.seriesSuggestions as SeriesSuggestions
    series.hideListAndCancel()
    const fields = this.$refs.fieldSuggestions as SelectableList
    fields.hideListAndCancel()
  }

  getSegmentIndexAtPosition(position: number): number {
    const tokensBeforeCurrent = this.searchTokens.slice(0, this.lastTokenIndex)
    const charsBeforeToken = tokensBeforeCurrent.length > 0 ? tokensBeforeCurrent.map(x => x.raw.length).reduce((a, b) => a + b, 0) : 0
    const token = this.searchTokens[this.lastTokenIndex]
    let positionRemaining = position - charsBeforeToken

    const segmentsWithWrappers = token.segments.map(x => {
      if (x.wrapper.length > 0) {
        return x.wrapper[0] + x.value + x.wrapper[1]
      } else {
        return x.value
      }
    })
    for (let i = 0; i < segmentsWithWrappers.length; i++) {
      positionRemaining -= segmentsWithWrappers[i].length
      if (positionRemaining <= 0) {
        return i
      }
    }
    return -1 // Out of bounds - should never reach this
  }

  getTokenIndexAtPosition(position: number): number {
    let tokenIndex = 0
    let totalLength = 0
    while (tokenIndex < this.searchTokens.length && totalLength + this.searchTokens[tokenIndex].raw.length < position) {
      totalLength += this.searchTokens[tokenIndex].raw.length
      tokenIndex += 1
    }
    if (tokenIndex >= this.searchTokens.length) {
      tokenIndex = this.searchTokens.length - 1
    }
    return tokenIndex
  }

  getTokenAtPosition(position: number): TokenizedSearchField {
    const genericEmptyToken = {
      field: '',
      segments: [
        {
          value: '',
          wrapper: '',
          raw: '',
        },
      ],
      raw: '',
    }
    const tokenIndex = this.getTokenIndexAtPosition(position)
    if (tokenIndex < 0) {
      return genericEmptyToken
    }
    return this.searchTokens[tokenIndex]
  }

  getSegments(valueText): TokenizedSearchSegment[] {
    const segmentedText = valueText.match(this.wrapperPattern)
    const segments = segmentedText ? segmentedText.map(x => {
      if (x.charAt(0) === '"' && x.charAt(x.length - 1) === '"') {
        return { value: x.substring(1, x.length - 1), wrapper: '""', raw: x }
      } else if (x.charAt(0) === '[' && x.charAt(x.length - 1) === ']') {
        return { value: x.substring(1, x.length - 1), wrapper: '[]', raw: x }
      } else if (x.charAt(0) === '(' && x.charAt(x.length - 1) === ')') {
        return { value: x.substring(1, x.length - 1), wrapper: '()', raw: x }
      } else {
        return { value: x, wrapper: '', raw: x }
      }
    }) : [{ value: '', wrapper: '', raw: '' }]
    return segments
  }

  fixLowercaseBooleans() {
    for (let i = 0; i < this.searchTokens.length; i++) {
      for (let j = 0; j < this.searchTokens[i].segments.length; j++) {
        if (this.searchTokens[i].segments[j].wrapper.length === 0 && this.lowercaseBool.test(this.searchTokens[i].segments[j].raw)) {
          this.searchTokens[i].segments[j].raw = this.searchTokens[i].segments[j].raw.replace(this.lowercaseBool, (match) => {
            return match.toUpperCase()
          })
        }
      }
      this.searchTokens[i].raw = this.searchTokens[i].segments.map(x => x.raw).join('')
    }
    // All the raws have been updated
    this.searchText = this.searchTokens.map(x => x.raw).join('')
  }

  setCursorPosition(index) {
    // Not doing error checking on the index, because by nextTick the searchText length should be updated
    this.cursorPosition = index
    const input = this.$refs.searchTextInput as HTMLInputElement
    input.focus()
    this.$nextTick(() => {
      input.setSelectionRange(index, index)
    })
  }

  moveCursorOutsideField() {
    if (this.currentSearchToken.field.length === 0) {
      return
    }
    const currentIndex = this.searchTokens.indexOf(this.currentSearchToken)
    // Only set the cursor JUST outside of the current field
    const newCursorPosition = this.searchTokens.map((x, index) => { if (index <= currentIndex) { return x.raw.length } else { return 0 } }).reduce((a, b) => a + b, 0)
    this.setCursorPosition(newCursorPosition)
  }

  getDefaultFieldWrapper(fieldAbbr) {
    // The wrapper set in PublicationsSearch.vue takes priority
    const searchField = this.searchFields.filter(x => x.value === fieldAbbr)[0]
    if (searchField.wrapper) {
      return searchField.wrapper
    }
    // The default wrapper for a particular format
    if (searchField.format === 'date' || searchField.format === 'year') {
      return '[]'
    } else if (searchField.format === 'author' || searchField.format === 'msc' || searchField.format === 'instcode') {
      return '""'
    }
    // There might be value in changing the default wrapper to be '' rather than '()'
    return '()'
  }

  addField(abbr) {
    if (this.currentSearchToken.field.length > 0) {
      // Need to identify when to push the moveCursorOutsideField, when to split the current token
      if (this.currentSearchToken.segments[this.currentSegmentIndex].wrapper.length > 0) {
        this.moveCursorOutsideField()
      }
    }
    const searchTextBefore = this.searchText.substring(0, this.cursorPosition)
    const searchTextAfter = this.searchText.substring(this.cursorPosition)
    // Create the `au:""` syntax
    // const searchField = this.searchFields.filter(x => x.value === abbr)[0]
    let newFieldText = abbr + ':' + this.getDefaultFieldWrapper(abbr)

    // Insert abbr and wrapper inbetween split text
    if (searchTextBefore.length > 0 && searchTextBefore[searchTextBefore.length - 1] !== ' ') {
      newFieldText = ' ' + newFieldText
    }
    // Need this length to correctly position the cursor later
    const newCursorPosition = searchTextBefore.length + newFieldText.length - 1

    if (searchTextAfter.length === 0 || searchTextAfter[0] !== ' ') {
      newFieldText += ' '
    }

    this.searchText = searchTextBefore + newFieldText + searchTextAfter
    // Next Tick to give moveCursorOutsideField time to act
    this.$nextTick(() => {
      // Update cursor position to be inbetween the wrappers
      this.setCursorPosition(newCursorPosition)
    })
  }

  addDate(text) {
    this.searchText += ` ${text}`
  }

  searchNewest(added = false, cataloged = false, reviewed = false, monthRange = 1) {
    // (this.$refs.searchBubbleInput as SearchBubbleInput).searchNewest(added, cataloged, reviewed)
    const today = new Date()
    const earlier = new Date()

    earlier.setMonth(earlier.getMonth() - monthRange) // Automatically handles year conversions BUT doesn't shorter months well.
    if (earlier.getDate() !== today.getDate()) {
      earlier.setDate(1) // Instead of going from March 2 to April 30 (since Feb 30 doesn't exist), it'll go March 1 to April 30
    }

    const startDate = String(earlier.getFullYear()) + '-' + String(earlier.getMonth() + 1).padStart(2, '0') + '-' + String(earlier.getDate()).padStart(2, '0')
    const endDate = String(today.getFullYear()) + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0')

    const parenNeeded = [added, cataloged, reviewed].filter(x => x === true).length > 1

    let searchString = ''
    if (parenNeeded) {
      searchString += '('
    }
    if (added) {
      searchString += `mp:[${startDate} ${endDate}] `
      if (cataloged || reviewed) {
        searchString += 'OR '
      }
    }
    if (cataloged) {
      searchString += `di:[${startDate} ${endDate}] `
      if (reviewed) {
        searchString += 'OR '
      }
    }
    if (reviewed) {
      searchString += `dr:[${startDate} ${endDate}]`
    }
    if (parenNeeded) {
      searchString += ')'
    }

    this.searchText = this.searchText + searchString
    this.$nextTick(() => {
      this.emitSearchEvent()
    })
  }

  classicSearch(text) {
    this.searchText = text
    this.showClassicInterface = false
    this.emitSearchEvent()
  }

  inputEnter() {
    this.$store.dispatch(RESET_LAST_SEARCH_STATE)
    // event.preventDefault alone in the SelectableList wasn't enough to keep the search from firing.
    if (this.allowSuggestions) {
      // Check if a selection has been hovered
      const hovered = document.querySelectorAll('.selectable-list .active')
      if (hovered) {
        const selected = Array.from(hovered).filter(x => x !== null && x !== undefined).map(x => window.getComputedStyle(x.parentElement!).display).filter(x => x !== 'none')
        if (selected.length === 0) {
          // Nothing hovered, do a search event
          this.emitSearchEvent()
        } // else, a suggestion was active
      } else {
        // Nothing hovered, do a search event
        this.emitSearchEvent()
      }
    } else {
      // There isn't a valid suggestion, so the enter must be to emit a search event
      this.emitSearchEvent()
    }
  }

  emitSearchEvent() {
    // Delay so SearchBubbleInput can refresh searchText
    this.lastSearchText = this.searchText.trim()
    this.showFields = false
    this.$nextTick(function() {
      if (this.continueSearching() && this.searchText.trim().length > 0) {
        if (this.selectedTab === SearchTabs.journal && this.selectedItemId !== '') {
          this.$router.replace({
            name: 'SerialProfile',
            query: { journalId: this.selectedItemId },
          })
        } else if (this.selectedTab === SearchTabs.series && this.selectedItemId !== '') {
          this.$router.replace({
            name: 'SerialProfile',
            query: { seriesId: this.selectedItemId },
          })
        } else {
          // Handle user shorthand
          const mrNumOnly = this.searchText.trim().toLowerCase().match(/^mr\d+$/)
          if (mrNumOnly !== null) {
            // It's just the mr number, format it and run it
            const formatted = 'mr:' + mrNumOnly[0].substring(2)
            this.searchText = formatted
          }
          // const cisOnly = this.searchText.trim().toLowerCase().match(/cis-\d+/)
          // if (cisOnly !== null) {
          //   const formatted = 'id:' + cisOnly[0].substr(4)
          //   this.searchText = formatted
          // }

          if (this.searchText.trim().match(/^\d+$/)) {
            // Only numbers were given, apply the default value
            if (this.selectedTab.name === this.SearchTabs.authors.name) {
              this.searchText = `auid:${this.searchText}`
            }
            if (this.selectedTab.name === this.SearchTabs.journal.name) {
              this.searchText = `ji:${this.searchText}`
            }
            if (this.selectedTab.name === this.SearchTabs.series.name) {
              this.searchText = `si:${this.searchText}`
            }
          }
          // (this.$refs.searchHistory as SearchHistory).appendSearchHistory(this.searchText)
          this.showHistory = false
          const history = (this.$refs.searchHistory as SearchHistory)
          history.appendSearchHistory(this.searchText)
          // history.setShowHistory(false) // We don't want to save this to the storage.
          this.$nextTick(() => {
            this.$emit('search', this.searchText)
          })
        }
      }
    })
  }

  continueSearching() {
    // Prevent the search triggering when it's an autocomplete being clicked on
    return this.searchWhenListIsOpened
      ? true
      : !(this.showList || this.showAutocompleteList)
  }

  formatter(left: string, right: string, text: string) {
    const endsWithSpace = /\s$/g
    const startsWithSpace = /^\s/g
    let before = this.selectedSeparator ? this.selectedSeparator.separators.before : ''
    let after = this.selectedSeparator ? this.selectedSeparator.separators.after : ''
    before = endsWithSpace.test(left) || left.trim().length === 0 ? before.trim() : before
    after = startsWithSpace.test(right) ? after.trim() : after

    return `${before}${text}${after}`
  }

  tagValidator(tag: string) {
    const tagRegex = /[^\s]*:([^\s]*)/
    return tag.match(tagRegex)
  }

  setShowHistory(value: boolean) {
    // This should only be called by the user hitting the button
    this.showHistory = value
    // this.allowShowHistory = value
    // this.$cookies.set(this.cookieName, value)
    const history = (this.$refs.searchHistory as SearchHistory)
    history.setShowHistory(value)
  }

  clearAll() {
    this.$store.dispatch(RESET_LAST_SEARCH_STATE)
    this.searchText = ''
    const input = this.$refs.searchTextInput as HTMLInputElement
    input.focus()
    this.$emit('clear', '')
    // this.showHistory = this.allowShowHistory // If the user hid history, do not show it now. Otherwise, show history
    const history = (this.$refs.searchHistory as SearchHistory)
    this.showHistory = history.getShowHistory() // If the user hid history, do not show it now. Otherwise, show history
  }

  toggleClassicInterface() {
    // Only used by the button.
    this.showClassicInterface = !this.showClassicInterface
    localStorage.setItem(this.$route.name + 'ShowClassicInterface', '' + this.showClassicInterface)
    if (this.showClassicInterface) {
      this.showHistory = false
      this.showFields = false
    }
  }

  toggleShowFields() {
    // Only used by the button.
    this.showFields = !this.showFields
    localStorage.setItem(this.$route.name + 'ShowFields', '' + this.showFields)
  }

  onItemSelectedHandler(item: ListItem) {
    this.selectedItemId = item.id ? item.id : ''
  }

  onClearFilters() {
    this.$store.dispatch(RESET_LAST_SEARCH_SEARCHPARAMS)
  }

  toggleSortMethod() {
    if (this.sortMethod === 'Tag') {
      this.sortMethod = 'Name'
    } else {
      this.sortMethod = 'Tag'
    }
    // localStorage.setItem(this.$route.name + 'FieldSortMethod', this.sortMethod)
    localStorage.setItem('FieldSortMethod', this.sortMethod)
  }
}
