
import { Component, Prop } from 'vue-property-decorator'
import { ListItem, SelectableList, SuggestionsList } from '@/components/selectable-list'
import { SerialsAPI } from '@/api/serials-api'

@Component({
  components: {
    SelectableList,
  },
})
export default class SeriesSuggestions extends SuggestionsList {
  @Prop({ required: true, default: false }) allowShowList!: boolean

  async fetchData(text: string) {
    const series = await SerialsAPI.getSeriesSuggestions(text, 10)

    this.autocompleteListValues = series.map(item => ({
      id: `${item.id}`.trim(),
      value: item.title,
      text: [item.titleHTML, item.issn],
    }))
  }

  hideListAndCancel() {
    (this.$refs.selectableList as SelectableList).hideListAndCancel()
  }

  private onItemSelectedHandler(item: ListItem) {
    this.$emit('itemSelected', item)
  }

  private handleFinalSelection(item) {
    this.$emit('finalSelection', item)
  }
}
