
import { Vue, Component, Prop } from 'vue-property-decorator'
import { SearchTab, SearchTabs } from './types'

@Component
export default class NavigationTabs extends Vue {
  @Prop() selectedTab!: SearchTab;

  //
  // REACTIVE PROPERTIES
  //
  tabs: SearchTab[] = [
    SearchTabs.publications,
    SearchTabs.authors,
    SearchTabs.journal,
    SearchTabs.series,
    SearchTabs.mscSearch,
  ]
}
